import React from "react";
import ImgixFluidImage from "./ImgixFluidImage";
import PlaceHolderImage from "./placeHolderImage";

const SectionHead = ({image, heading, title, date, subtitle, byline, email, linkedin, ar=1100/400 }) => {

  const finalImage = image ? <ImgixFluidImage attribution={image.attribution} imageUrl={image.url} sizes="100vw" ar={ar} focalPoint={image.focalPoint}/>  : <PlaceHolderImage />;

  return (
    <header className="section__head">
      {image ? <figure className="section__head-image">{finalImage}</figure> : <><figure className="section__head-image"><br/></figure></>}
      {heading ? <h1 className="section__head-title h2 text-gray">{heading}</h1> : <></>} 
      {title ? <p className="section__title title h2 text-gray">{title}</p> : <></>} 
      {subtitle ? <p className="section__subtitle subtitle text-orange">{subtitle}</p> : <></>} 
      {date ? <p className="section__head-date h4" style={{ marginBottom: "0.5rem" }}><em>{date}</em></p> : <></>} 
      {byline ? <p className="section__head-date h6">{byline}</p> : <></>}
      {email ? <a href={`mailto: ${email}`} class="inline-block text-green">
        <svg fill="currentColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 483.3 483.3" height="32" width="32">
          <g><g><path d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1
              v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5
              c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"/><path d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3
              c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95
              c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4
              c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
        </svg>
      </a> : <></>} 
      {linkedin ? <a href={linkedin} target="_blank" rel="noreferrer" class="inline-block text-green" style={{ marginLeft: "0.5rem" }}>
        <svg id="linkedin" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 18 18"><path id="Shape" d="M14.4 18H3.6A3.6 3.6 0 010 14.4V3.6A3.6 3.6 0 013.6 0h10.8A3.6 3.6 0 0118 3.6v10.8a3.6 3.6 0 01-3.6 3.6zM3.6 1.8a1.8 1.8 0 00-1.8 1.8v10.8a1.8 1.8 0 001.8 1.8h10.8a1.8 1.8 0 001.8-1.8V3.6a1.8 1.8 0 00-1.8-1.8zm9.9 12.6a.9.9 0 01-.9-.9v-3.087A1.44 1.44 0 0011.25 9a1.587 1.587 0 00-1.35.867V13.5a.9.9 0 01-1.8 0V8.1a.9.9 0 011.638-.515A3.167 3.167 0 0111.25 7.2a3.229 3.229 0 013.15 3.214V13.5a.9.9 0 01-.9.9zm-8.1 0a.9.9 0 01-.9-.9V8.1a.9.9 0 111.8 0v5.4a.9.9 0 01-.9.9zm0-8.1a1.35 1.35 0 111.35-1.35A1.352 1.352 0 015.4 6.3z"/></svg>
      </a> : <></>} 
    </header>
  );
};

export default SectionHead;