import { Link } from "gatsby";
import React from "react";
import ImgixFluidImage from "./ImgixFluidImage";
import PlaceHolderImage from "./placeHolderImage";

const Card = ({image, standalone = false, readMoreLabel="Read more", title, subtitle, href, color, hasFocalPoint = true, sizes = "(min-width: 1000px) 350px, (min-width: 768px) 400px, 640px", ar, placeHolderImage, className, useImage = true, replacementImage, truncateText = true, isStaff = false, ...other }) => {

  let link;

  if (href) {
     // all internal links should be relative so as soon as there is an http:// in the url we can assume it is external
    link = (href.indexOf("://")=== -1 && href.indexOf("mailto")=== -1) ? <Link to={href} className="box__link-main" /> : <a href={href} className="box__link-main" target="_blank" rel="noreferrer" aria-label="link"><div style={{display: 'none'}}>Link</div></a>
  } else {
    link = "";
  }

  const firstImage = image ? <ImgixFluidImage attribution={image.attribution} imageUrl={image.url} sizes={sizes} ar={ar || 2} focalPoint={image.focalPoint} />  : <PlaceHolderImage image={placeHolderImage} ar={ar || 2} />;
  const finalImage = replacementImage ? replacementImage : firstImage;

  const colors = ["", "box--red", "box--orange"];

  // some cards aren't in 3s. Ths class removes flex 33%
  const standaloneClass = standalone ? "standalone" : "";

  return (
    <div className={`box ${isStaff ? 'box-staff' : ''} ${standaloneClass} box--secondary ${colors[color]} swiper-slide ${className}`} {...other}>
      <div className="box__inner">
        {link}
        {useImage && <figure className="image-fit js-image-fit box__image">{finalImage}</figure>} 
        <div className="box__body">
          <p className="box__title h6">{title}</p>
          <div dangerouslySetInnerHTML={{ __html: subtitle }} className={`box__subtitle${truncateText ? " truncate-overflow" : ""}`}></div>
          {href && <div className="box__link link-more text-gray-3">{readMoreLabel}</div>}
        </div>
      </div>
    </div>
  );
};

export default Card;