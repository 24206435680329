import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Pagination from "../components/newComponents/pagination";
import Card from "../components/newComponents/card";
import SectionHead from "../components/newComponents/sectionHead";

export const query = graphql`
  query NewlettersQuery($limit: Int, $skip: Int) {
    newsletters: allCraftNewslettersNewslettersEntry(sort: {fields: postDate, order: DESC}, limit: $limit, skip: $skip) {
      nodes {
        uri
        displayTitle
        summary
        newsletterLink
        downloads {
          url
          size
          kind
        }
        featureImage {
          ...AssetFragment
        }
      }
    }
  }
`;

const NewsletterListing = ({data: {newsletters}, pageContext}) => (
  <Layout pageContext={pageContext}>
    <Seo title="Newsletters" />
    <div className="main">
      <section className="section-boxes section-boxes--news section-boxes--news-secondary">
        <div className="shell">
          <SectionHead title="Newsletters" />
          <div className="boxes boxes--news swiper-container js-slider-boxes-news">
            <ul>
              {newsletters.nodes.map((item, i) => (
                <Card key={`newsletters-listing-${i}`} image={item.featureImage[0]} ar={11/4} title={item.displayTitle} subtitle={item.summary} href={`/${item.uri}`}/>
              ))}
            </ul>
            <div className="swiper-pagination boxes__pagination" />
          </div>
          {pageContext.totalPages > 1 && (
            <Pagination nextUrl={pageContext.nextUrl} prevUrl={pageContext.prevUrl} currentPage={pageContext.currentPage} base="/newsletters" totalPages={pageContext.totalPages} />
          )}
        </div>
      </section>
    </div>
  </Layout>
);

export default NewsletterListing;