import React from "react";
import { Link } from "gatsby";

function paginationStrings(current, total, space) {
  
  let strings = [{index: 1, label: "1"}];

  if(space < total - 2) {

    const offset = Math.max(0, Math.ceil(space / 2) + 2 - current) + (Math.min(0, total - current - Math.ceil(space / 2) + 1));
    if(current - Math.ceil(space / 2) + Math.ceil(space % 2) - 1 + offset !== 2) strings.push({index: null, label: "..."});

    Array.from({ length: space}).map((_, i) => {
      const num = current + i - Math.ceil(space / 2) + Math.ceil(space % 2) - 1 + offset;
      strings.push({index: num, label: `${num}`});
      return null;
    });

    if(current + space - Math.ceil(space / 2) + Math.ceil(space % 2) - 1 + offset !== total) strings.push({index: null, label: "..."});
    strings.push({index: total, label: `${total}`});

  } else {

    Array.from({ length: total - 1}).map((_, i) => {
      strings.push({index: i + 2, label: `${i + 2}`});
      return null;
    });
  }
  
  return(strings);
}

const Pagination = ({ prevUrl, nextUrl, totalPages, currentPage, base }) => (
  <div className="section__paging paging">
    <Link to={currentPage === 1 ? null : prevUrl} className="paging__prev">Previous</Link>
    <ul>
      {paginationStrings(currentPage + 1, totalPages, 5).map((item, i) => (
        <li key={`page-${i}`}>
          <Link activeClassName="font-black" to={item.index ? `${base}${item.index > 1 ? "/p" : ""}${item.index > 1 ? item.index: ""}` : null}>{item.label}</Link>
        </li>
      ))}
    </ul>
    <Link to={currentPage === totalPages ? null : nextUrl} className="paging__next">Next</Link>
</div>
);

export default Pagination;