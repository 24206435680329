import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import ImgixFluidImage from "./ImgixFluidImage";

const PlaceHolderImage = ({image, ar=3/2 }) => {

    const data = useStaticQuery(graphql`
        
        query PlaceHolderImageQuery {

            placeHolderImage:  allCraftGlobalsGlobalSet {
                edges {
                    node {
                        id
                        siteImage {
                            ... on Craft_uploads_Asset {
                                ...AssetFragment
                            }
                        }
                    }
                }
            }
        }
    `);

    image = image || data.placeHolderImage.edges[0].node.siteImage[0];

    return (
        <ImgixFluidImage imageUrl={image.url} sizes="100vw" ar={ar} focalPoint={image.focalPoint}/>
    );
};

export default PlaceHolderImage;